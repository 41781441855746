import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getInternalFundsTransfers: createCancelTokenHandler('getInternalFundsTransfers'),
  getInternalFundsTransferActivitiesLog: createCancelTokenHandler('getInternalFundsTransferActivitiesLog')
}
function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getInternalFundsTransfers (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/internal-fund${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getInternalFundsTransfers'].handleRequestCancellation().token
  })
}

export function getInternalFundsTransfer (id) {

  return axios({
    url: `v1/admin/treasury/internal-fund/${id}`,
    method: 'get'
  })
}

export function printInternalFundsTransfer ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/internal-fund?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getInternalFundsTransferActivitiesLog (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/internal-fund/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getInternalFundsTransferActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertInternalFundsTransfer (payment) {

  return axios({
    url: 'v1/admin/treasury/internal-fund',
    data: payment,
    method: 'post'
  })
}

export function deleteInternalFundsTransfer (id) {
  return axios({
    url: `v1/admin/treasury/internal-fund/${id}`,
    method: 'delete'
  })
}
